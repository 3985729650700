<template>
  <div id="app">
    <transition name="overflow-fade">
      <div
        v-if="$root.ready && overflow"
        class="ready-overflow"
        @click="openWebsite()"
      >
        <div class="text-center w-100">
          <div class="title">Undangan {{ title }}</div>
          <span>from</span>
          <div class="mb-5 name">Mang Fira</div>

          <button
            class="text-uppercase btn btn-outline-primary"
            @click="openWebsite()"
          >
            Buka Undangan
          </button>
        </div>
      </div>
    </transition>

    <AGWAudio ref="audio" :file="music.file" :loop="music.loop" :autoplay="music.autoplay"/>
    <AGWHero overlay :background="backgrounds" ref="hero" parallax target="#intro"/>

    <div class="bg-white agw-content" :style="{ 'margin-top' : `${$root.hero.height}px` }">
      <AGWIntro id="intro"/>

      <div class="pad-y-60">
        <AGWPawiwahan id="pawiwahan"/>
        <!-- <AGWMepandes id="mepandes" class="mar-top-60" :mepandes="mepandes"/> -->
      </div>

      <AGWCounter
        id="counter"
        class="pad-y-60"
        :background="background"
        :date="time.start"
      />
      <AGWGallery id="gallery" class="pad-y-60"/>
      <AGWMap
        :height="450"
        url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3942.743289083873!2d115.11048581478505!3d-8.810170593672874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb5142ad85abfe4fb!2zOMKwNDgnMzYuNiJTIDExNcKwMDYnNDUuNiJF!5e0!3m2!1sen!2sid!4v1646013071207!5m2!1sen!2sid"
        directionUrl="https://www.google.com/maps/dir//-8.8101706,115.1126745/@-8.8101706,115.1104858,17z?hl=en"
        :location="location"
        :time="time"
        id="map"
      />
    </div>

    <div class="agw-content" style="background: transparent">
      <Himbauan />
      <attendance id="attendance" class="pad-y-60"></attendance>
    </div>

    <div :style="{ 'height' : `${$root.dividerHeight}px` }"></div>

    <Footer ref="footer" />

    <transition name="slide-fade">
      <guest-box :floating="true" v-if="$root.scroll.Y > $root.hero.height"/>
    </transition>

    <div class="snackbar" v-if="sw.updateExists">
      New version available!
      <button @click="refreshApp" class="btn">REFRESH</button>
    </div>
  </div>
</template>

<script>
const ImageHero1 = 'https://ik.imagekit.io/crk/mang-fina/RE2_3121.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646013436890&tr=w-1024'
const ImageHero2 = 'https://ik.imagekit.io/crk/mang-fina/RE2_3069.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646013436330&tr=w-1024'
const ImageHero3 = 'https://ik.imagekit.io/crk/mang-fina/RE2_3011.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646013479250&tr=w-1024'

import Audio from "@/assets/audio/soundtrack.mp3";

import AGWHero from "@/components/Hero";
import AGWIntro from "@/components/Intro";
import AGWPawiwahan from "@/components/Pawiwahan";
import AGWMepandes from "@/components/Mepandes";
import AGWCounter from "@/components/Counter";
import AGWGallery from "@/components/Gallery";
import AGWMap from "@/components/Maps";
import AGWAudio from "@/components/Audio";
import GuestBox from "@/components/GuestBox";
import Himbauan from '@/components/Himbauan.vue';
import Attendance from "@/components/Attendance";
import Footer from "@/components/Footer/Remind.vue";

import { setInterval } from "timers";

export default {
  name: "app",
  components: {
    AGWHero,
    AGWIntro,
    AGWPawiwahan,
    AGWMepandes,
    AGWCounter,
    AGWGallery,
    AGWMap,
    AGWAudio,
    GuestBox,
    Attendance,
    Himbauan,
    Footer,
  },
  data() {
    return {
      title: 'Mepandes',
      backgrounds: [ImageHero1, ImageHero2, ImageHero3],
      bgActive: 0,
      dividerHeight: 0,
      overflow: true,
      wedding: {
        man: {
          name: 'I Komang Aris Sutyawan',
          surname: 'Aris',
          parents: 'I Nyoman Suwidra & Ni Wayan Sutriani',
          description: 'Putra ketiga',
          address: 'Br. Dinas Tambyak, Pecatu',
          photo: 'https://ik.imagekit.io/aryraditya/ceremonyku/aris-amelia/20211124-IMG_4854-untitled.jpg?updatedAt=1641024218956&tr=w-400,h-499,fo-custom,cm-extract',
        },
        woman: {
          name: 'Ni Luh Putu Amelia Putri',
          surname: 'Amelia',
          parents: 'I Wayan Suka Adnyana & Ni Nyoman Mariasih',
          address: 'Br. Dinas Kauh, Pecatu',
          description: 'Putri pertama',
          photo: 'https://ik.imagekit.io/aryraditya/ceremonyku/aris-amelia/20211124-IMG_4784-untitled.jpg?updatedAt=1641024218580&tr=w-400,h-500,fo-custom,cm-extract',
        },
      },
      location: "Jl. Tanjung Simah No. 9, Labuhan Sait, Pecatu, Kuta Selatan, Badung - Bali",
      time: {
        start: this.$moment("2022-03-07 14:00"),
        end: "Selesai"
      },
      mepandes: [
        {
          name: 'Ni Komang Fira Ferlinda',
          image: 'https://ik.imagekit.io/crk/mang-fina/RE2_3011.jpg?updatedAt=1646013479250&tr=w-400,h-501,fo-custom,cm-extract',
          parents: 'I Wayan Pujana & Ni Ketut Perniasih',
          description: 'Putri ketiga dari',
          address: 'Br. Dinas Labuhan Sait, Pecatu, Kuta Selatan, Badung.',
        }
      ],
      music: {
        file: Audio,
        autoplay: true,
        loop: true
      },
      sw: {
        refreshing: false,
        registration: null,
        updateExists: false
      }
    };
  },
  computed: {
    background() {
      return this.backgrounds[this.bgActive];
    }
  },
  mounted() {
    this.dividerHeight = `calc(100vh - ${this.$refs.footer.offsetHeight}px)`;
    setInterval(() => {
      let active = this.bgActive + 1;
      if (active >= this.backgrounds.length) {
        active = 0;
      }

      this.bgActive = active;
    }, 5000);
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    // if(navigator.serviceWorker) {
    //   navigator.serviceWorker.addEventListener("controllerchange", () => {
    //   if (this.sw.refreshing) return;
    //   // this.sw.refreshing = true;
    //   // window.location.reload();
    // });
    // }
  },
  methods: {
    showRefreshUI(e) {
      this.sw.registration = e.detail;
      this.sw.updateExists = true;
    },
    refreshApp() {
      this.sw.updateExists = false;
      if (!this.sw.registration || !this.sw.registration.waiting) {
        return;
      }
      this.sw.registration.waiting.postMessage("skipWaiting");
      this.sw.refreshing = true;
      window.location.reload();
    },
    openWebsite() {
      this.overflow = false;
      document.querySelector("#loading").classList.remove("active");
      if (this.$refs.audio) this.$refs.audio.play();
    }
  }
};
</script>
